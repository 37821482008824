<template>
  <div class="bg-default bg-news">
    <header class="header">
      <div class="header-left">
        <a href="#">
          <img
            class="header-settings-icon"
            src="img/settings-icon.png"
            alt="Settings"
          />
        </a>
      </div>
      <div class="header-center justify-between">
        <div class="header-card">
          <div class="header-card-info">
            <div class="header-card-info-title">Devizám</div>
            <div class="header-card-info-number">
              {{ deviza.toString() }}
            </div>
          </div>
        </div>
        <div class="header-card">
          <div class="header-card-info">
            <div class="header-card-info-title">Értékpapírjaim összértéke</div>
            <div class="header-card-info-number">
              {{ allSecuritiesSum }}
            </div>
          </div>
        </div>
        <div class="header-card">
          <div class="header-card-text">
            hátralévő<br />
            idő
          </div>
          <MarketTimer
            @roundEnded="roundEnd()"
            @resultEnded="resultEnd()"
            :startTime="timingOption"
          ></MarketTimer>
        </div>
      </div>
      <div class="header-right">
        <a @click="exit()">
          <img
            class="header-settings-icon"
            src="img/exit-icon.png"
            alt="Exit"
          />
        </a>
      </div>
    </header>

    <main class="main">
      <div class="container">
        <div v-if="!isResultRound" class="news">
          <div v-if="!this.news.articles[round - 1].news2" class="news-info">
            {{$t(this.news.articles[round - 1].news1)}}
          </div>
          <div v-else class="news-info">
            {{
              newsSwitch
                ? $t(this.news.articles[round - 1].news1)
                : $t(this.news.articles[round - 1].news2)
            }}
          </div>
        </div>

        <div v-else>
          <div v-if="difference < 0" class="message message-fail">
            <div class="message-info">
              <div class="message-info-title">Veszteséggel kereskedtél</div>
              <div class="message-info-number">{{ difference }} USD</div>
            </div>
          </div>

          <div v-if="difference > 0" class="message message-success">
            <div class="message-info">
              <div class="message-info-title">Nyereséggel kereskedtél</div>
              <div class="message-info-number">{{ difference }} USD</div>
            </div>
          </div>
          <div v-else class="message">
            <div class="message-info">
              <div class="message-info-title">{{ " " }}</div>
              <div class="message-info-number">{{ " " }}</div>
            </div>
          </div>
        </div>
        <div v-if="!showInformation" class="list mt-8">
          <div class="list-header">
            <div class="list-header-item list-item-company">cég</div>
            <div class="list-header-item list-item-company-info">céginfók</div>
            <div class="list-header-item list-item-exchange-rate">árfolyam</div>
            <div class="list-header-item list-item-securities-sum">
              értékpapírok összértéke
            </div>
            <div class="list-header-item list-item-securities-num">
              értékpapírok száma
            </div>
          </div>

          <div :class="[this.difficulty === 1 ?'list-body-easy' :'list-body-else']">
            <div
              v-for="company in companies"
              :key="company.id"
              class="list-body-items"
            >
              <div class="list-body-item list-item-company">
                <div class="company">
                  <div class="company-logo">
                    <img src="img/arrow-green.svg" alt="Logo" />
                  </div>
                  <div class="company-right">
                    <div class="company-short-name">
                      {{ company.name }}
                    </div>
                    <div class="company-name">
                      {{ company.description }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-body-item list-item-company-info">
                <div class="company-info">
                  <a @click="showCompany(company)" class="company-info-circle">
                    <span>i</span>
                  </a>
                </div>
              </div>
              <div class="list-body-item list-item-exchange-rate">
                <div
                  :class="[
                    'exchange-rate',
                    company.exchangeRateChange < 1 && 'exchange-rate-down',
                    company.exchangeRateChange > 1 && 'exchange-rate-up',
                  ]"
                >
                  <div class="exchange-rate-left">
                    <img
                      v-if="company.exchangeRateChange > 1"
                      src="img/arrow-green.svg"
                      class="exchange-rate-arrow-green"
                      alt="Exchange rate arrow"
                    />
                    <img
                      v-if="company.exchangeRateChange < 1"
                      src="img/arrow-red.svg"
                      class="exchange-rate-arrow-red"
                      alt="Exchange rate arrow"
                    />
                  </div>
                  <div class="exchange-rate-center">
                    <div class="exchange-rate-number">
                      {{ company.initialStockPrice }}
                    </div>
                  </div>
                  <div class="exchange-rate-right">
                    <div class="exchange-rate-percent">
                      {{
                        company.exchangeRateChange === 1
                          ? ""
                          : company.exchangeRateChange
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-body-item list-item-securities-sum">
                <div class="securities-sum">
                  {{ company.securitiesAmountSum }}
                </div>
              </div>
              <div class="list-body-item list-item-securities-num">
                <div class="securities-num">
                  <div class="securities-num-left">
                    <a
                      @click="sellSecurities(company)"
                      class="
                        button button-small button-shadow-small button-orange
                      "
                      >Elad</a
                    >
                  </div>
                  <div class="securities-num-center">
                    <div class="securities-num-number">
                      {{ company.securitiesCount }}
                    </div>
                  </div>
                  <div class="securities-num-right">
                    <a
                      @click="buySecurities(company)"
                      class="
                        button button-small button-shadow-small button-blue
                      "
                      >Vesz</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showInformation" class="card mt-48">
          <div class="card-special-top">
            <div class="card-company-logo">
              <div class="company">
                <div class="company-logo">
                  <img src="img-placeholder.png" alt="Logo" />
                </div>
                <div class="company-right">
                  <div class="company-short-name">
                    {{ selectedCompany.name }}
                  </div>
                  <div class="company-name">
                    {{ selectedCompany.description }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-close">
              <a
                @click="closeCompanyInformation()"
                class="
                  button
                  button-small
                  button-shadow-small
                  button-blue
                  button-close
                "
                >Bezár</a
              >
            </div>
          </div>
          <div class="card-company-content">
            <div class="card-company-content-top">
              <div class="card-company-content-top-info">
                <div class="card-company-content-top-info-title">alapítva</div>
                <div class="card-company-content-top-info-data">
                  {{ selectedCompany.founded }}
                </div>
              </div>
              <div class="card-company-content-top-info">
                <div class="card-company-content-top-info-title">székhely</div>
                <div class="card-company-content-top-info-data">
                  {{ selectedCompany.headquarters }}
                </div>
              </div>
              <div class="card-company-content-top-info">
                <div class="card-company-content-top-info-title">
                  éves árbevétel
                </div>
                <div class="card-company-content-top-info-data">
                  {{ selectedCompany.salesRevenue }}
                </div>
              </div>
              <div class="card-company-content-top-info">
                <div class="card-company-content-top-info-title">
                  éves profit
                </div>
                <div class="card-company-content-top-info-data">
                  {{ selectedCompany.profit }}
                </div>
              </div>
            </div>
            <div class="card-company-content-bottom">
              <div class="card-company-content-bottom-left">
                <div class="card-company-content-bottom-left-employees mb-64">
                  <div class="card-company-content-bottom-title">
                    alkalmazottak
                  </div>
                  <div class="card-company-content-bottom-data">
                    {{ selectedCompany.employees }}
                  </div>
                </div>
                <div class="card-company-content-bottom-left-scope">
                  <div class="card-company-content-bottom-title">
                    tevékenységi kör
                  </div>
                  <div class="card-company-content-bottom-data">
                    <p
                      v-for="(activity, i) in selectedCompany.activities"
                      :key="i"
                    >
                      {{ $t(activity) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-company-content-bottom-right">
                <div class="card-company-content-bottom-title">
                  árbevételi megoszlás
                </div>
                <div class="card-company-content-bottom-chart">
                  <img src="img/nations-chart.png" alt="Nations chart" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer"></footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketTimer from "../components/MarketTimer.vue";

const newsJson = require("../../news.json");

export default {
  components: {
    MarketTimer,
  },
  data() {
    return {
      companies: null,
      news: null,
      round: 1,
      newsSwitch: true,
      switchInterval: null,
      showInformation: false,
      selectedCompany: null,
      timeInterval: null,
      success: false,
      difference: 0,
      resultInterval: null,
      oldSecuritiesSum: null,
      isResultRound: false,
      groupIsWaiting: false,
      timingOption: {
        time: this.$config.settings.marketTime,
        option: "timer",
      },
    };
  },
  computed: {
    ...mapGetters([
      "deviza",
      "difficulty",
      "gameplayId",
      "groupId",
      "actualGameTurnId",
      "stopGameTurnId",
      "newsGroupIndex"
      // ...
    ]),
    allSecuritiesSum: function () {
      let sum = 0;
      this.companies.forEach((company) => (sum += company.securitiesAmountSum));
      return sum;
    },
  },
  created() {
    // 0 itt random lesz a szmemélyesben groupban meg egy közös.
    let randomNewsGroupIndex = this.randomIntFromInterval(0,4)
    if(this.groupId) {
      randomNewsGroupIndex = this.newsGroupIndex;
    }
    
    if (this.difficulty === 1) {
      this.news = newsJson.news.beginner[randomNewsGroupIndex];
    } else if (this.difficulty === 2) {
      this.news = newsJson.news.intermediate[randomNewsGroupIndex];
    } else if (this.difficulty === 3) {
      this.news = newsJson.news.advanced[randomNewsGroupIndex]; // kimaradt 1 !!!!!
    }
    let updatedCompanies = [];
    newsJson.companies.forEach((company) => {
      company.securitiesCount = 0;
      company.securitiesAmountSum = 0;
      company.exchangeRateChange = 1;
      if (this.difficulty === 1) {
        if (company.isBeginner) return updatedCompanies.push(company);
        return;
      }
      return updatedCompanies.push(company);
    });

    this.companies = updatedCompanies;
  },
  mounted() {
    this.setSwitchInterval();
    this.storeGameplayEvent();
    //this.setTimeInterval(30);
  },
  methods: {
    formatPercent() {},
    buySecurities(company) {
      if (this.isResultRound) return;
      if (this.deviza - company.initialStockPrice < 0) return;
      company.securitiesCount++;
      company.securitiesAmountSum =
        company.securitiesCount * company.initialStockPrice;
      this.$store.commit("setDeviza", this.deviza - company.initialStockPrice);
    },
    sellSecurities(company) {
      if (this.isResultRound) return;
      if (company.securitiesCount === 0) return;
      company.securitiesCount--;
      company.securitiesAmountSum =
        company.securitiesCount * company.initialStockPrice;
      this.$store.commit("setDeviza", this.deviza + company.initialStockPrice);
    },
    nextRound() {
      let sum = 0;
      this.oldSecuritiesSum = this.allSecuritiesSum;
      this.companies.forEach((company) => {
        this.news.articles[this.round - 1].effect.forEach((effect) => {
          if (company.id === effect.companyId) {
            company.exchangeRateChange = effect.change;
            company.securitiesAmountSum = Math.round(
              effect.change * company.securitiesAmountSum
            );
            // console.log(
            //   "newAmountSum",
            //   Math.round(effect.change * company.securitiesAmountSum)
            // );
            company.initialStockPrice = Math.round(
              effect.change * company.initialStockPrice
            );
          }
        });
      });
      this.difference = this.allSecuritiesSum - this.oldSecuritiesSum;
      this.$store.dispatch('saveBalanceChange',  {balance: Math.round((this.difference * this.$config.game.prices.valutaPurchaseRate)),});
      this.$store.commit("addProfit", this.difference);
      this.isResultRound = true;
    },
    showCompany(company) {
      this.selectedCompany = company;
      this.showInformation = !this.showInformation;
    },
    closeCompanyInformation() {
      this.showInformation = !this.showInformation;
    },
    roundEnd() {
      this.nextRound();
      this.setTimingOption(this.$config.settings.resultTime,"result");
    },
    resultEnd() {
      if (this.round === this.news.articles.length) {
        this.goNext();
        this.$store.commit("setSecuritiesAmountSum", this.allSecuritiesSum);
        return 1;
      }
      //Új kör
      if(this.groupId) {
        // console.log("GROUPID")
        if(this.actualGameTurnId === this.stopGameTurnId) {
          this.groupIsWaiting = true; //Ha itt megállítjuk akkor utána csak a watcher dobja be a következő körre.
          return;
        }
      }
      
      this.isResultRound = false;
      this.groupIsWaiting = false;
      this.nextQuestion();
      this.setTimingOption(this.$config.settings.marketTime,"market");
      this.storeGameplayEvent();
      this.resetEveryExchangeRateChange();
    },
    resetEveryExchangeRateChange() {
      this.companies.forEach((company) => (company.exchangeRateChange = 1));
    },
    setSwitchInterval() {
      this.switchInterval = setInterval(() => {
        this.newsSwitch = !this.newsSwitch;
      }, this.$config.settings.newsSwitchTime);
    },
    goNext() {
      this.$router.push("securities-exchange");
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    nextQuestion() {
      this.round++;
    },
    storeGameplayEvent() {
      this.$store.dispatch("storeGameplayEvent", {
                round: this.round,
                event: "round_start",
      });
    },
    setTimingOption(time,option) {
      this.timingOption = {
        time: time,
        option: option,
      };
    }
  },

  beforeDestroy() {
    clearInterval(this.switchInterval);
  },
  watch: {
    stopGameTurnId(newTurn,oldTurn) {
      // console.log("newtzrn");
          if(newTurn !== this.actualGameTurnId) {
            if(this.groupIsWaiting) {
              this.isResultRound = false;
      this.nextQuestion();
      this.setTimingOption(this.$config.settings.marketTime,"market");
      this.storeGameplayEvent();
      this.resetEveryExchangeRateChange();
      this.groupIsWaiting = false;
            }
          }
      },

  }
};
</script>

<style scoped>
.list-body-easy {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    max-height: 1200px;
    /* overflow-y: auto; */
    height: 0;
    animation: 0.7s linear 0s 1 expandBottomEasy;
    animation-fill-mode: forwards;
}

.list-body-else {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    max-height: 1200px;
    /* overflow-y: auto; */
    height: 0;
    animation: 0.7s linear 0s 1 expandBottom;
    animation-fill-mode: forwards;
}

@keyframes expandBottom {
    to {
        height: 820px;
    }
}

@keyframes expandBottomEasy {
    to {
        height: 540px;
    }
}
</style>